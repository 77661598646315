define("discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-artist-credits", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @artists as |c|~}}
      {{listenbrainz-linked-artist credits=c~}}{{c.join_phrase}}
  {{~/each}}
  
  */
  {
    "id": "eqmD58Z2",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,[28,[35,2],null,[[\"credits\"],[[30,2]]]]],[1,[30,2,[\"join_phrase\"]]]],[2]],null]],[\"@artists\",\"c\"],false,[\"each\",\"-track-array\",\"listenbrainz-linked-artist\"]]",
    "moduleName": "discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-artist-credits.hbs",
    "isStrictMode": false
  });
});