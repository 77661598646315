define("discourse/plugins/listenbrainz/discourse/lib/listenbrainz-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildMbidUrl = buildMbidUrl;
  function buildMbidUrl(entity, mbid) {
    if (mbid) {
      var hostname = 'musicbrainz.org';
      if (entity == 'album' || entity == 'release' || entity == 'artist') {
        hostname = 'listenbrainz.org';
      }
      return `https://${hostname}/${encodeURIComponent(entity)}/${encodeURIComponent(mbid)}`;
    }
  }
});