define("discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-cover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showCover}}
  <img src="{{this.src}}"
      title="{{this.title}}"
      {{on "load" this.onLoad}}
      style="{{this.style}}" />
  {{else}}
  <span />
  {{/if}}
  
  */
  {
    "id": "n996JnZX",
    "block": "[[[41,[30,0,[\"showCover\"]],[[[11,\"img\"],[16,\"src\",[29,[[30,0,[\"src\"]]]]],[16,\"title\",[29,[[30,0,[\"title\"]]]]],[16,5,[30,0,[\"style\"]]],[4,[38,1],[\"load\",[30,0,[\"onLoad\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[10,1],[12],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"on\"]]",
    "moduleName": "discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-cover.hbs",
    "isStrictMode": false
  });
});