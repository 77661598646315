define("discourse/plugins/listenbrainz/discourse/components/listenbrainz-listening", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "discourse/plugins/listenbrainz/discourse/lib/listenbrainz-utils"], function (_exports, _component, _tracking, _ajax, _listenbrainzUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // 280 characters is the maximum length of the blurb allowed by ListenBrainz UI.
  // But it might be longer if created by API.
  const maxBlurbLength = 280;
  class ListeningComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      this.user = args.user;
      this.loadNowPlaying();
    }
    static #_ = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return true;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return true;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "listen", [_tracking.tracked], function () {
      return null;
    }))();
    #listen = (() => (dt7948.i(this, "listen"), void 0))();
    get isPlayingNow() {
      return !!this.listen?.playing_now;
    }
    get isPin() {
      return !!this.listen?.pinned_until;
    }
    get artist() {
      return this.artistCredits.reduce((result, c) => {
        result.push(c.artist_credit_name);
        if (c.join_phrase) {
          result.push(c.join_phrase);
        }
        return result;
      }, []).join('');
    }
    get title() {
      return this.listen?.track_metadata?.track_name;
    }
    get album() {
      return this.listen?.track_metadata?.release_name;
    }
    get artistCredits() {
      const metadata = this.listen?.track_metadata;
      let artists = metadata?.mbid_mapping?.artists;
      if (!artists || artists.length === 0) {
        // No detailed artist credits, build a single credit from artist name
        const artistMbids = metadata?.additional_info?.artist_mbids || metadata?.mbid_mapping?.artist_mbids;
        artists = [{
          // Link only if there is exactly one artist
          artist_mbid: artistMbids?.length === 1 ? artistMbids[0] : null,
          artist_credit_name: metadata?.mbid_mapping?.artist_credit_name || metadata?.artist_name,
          join_phrase: ''
        }];
      }
      return artists;
    }
    get duration() {
      const additional_info = this.listen?.track_metadata?.additional_info;
      if (additional_info) {
        const duration = additional_info.duration_ms ? additional_info.duration_ms / 1000 : additional_info.duration;
        if (duration) {
          const minutes = parseInt(duration / 60, 10);
          const seconds = String(parseInt(duration % 60, 10)).padStart(2, '0');
          return `${minutes}:${seconds}`;
        }
      }
      return '';
    }
    get listenDateTime() {
      const timestamp = this.listen?.listened_at || this.listen?.created;
      return timestamp ? new Date(timestamp * 1000) : null;
    }
    get recordingMbid() {
      const metadata = this.listen?.track_metadata;
      return metadata?.additional_info?.recording_mbid || metadata?.mbid_mapping?.recording_mbid;
    }
    get releaseMbid() {
      const metadata = this.listen?.track_metadata;
      return metadata?.additional_info?.release_mbid || metadata?.mbid_mapping?.release_mbid;
    }
    get recordingUrl() {
      return (0, _listenbrainzUtils.buildMbidUrl)('recording', this.recordingMbid);
    }
    get releaseUrl() {
      return (0, _listenbrainzUtils.buildMbidUrl)('release', this.releaseMbid);
    }
    get playUrl() {
      const recordingMbid = this.recordingMbid;
      if (recordingMbid) {
        return `https://listenbrainz.org/player?recording_mbids=${encodeURIComponent(recordingMbid)}`;
      }
    }
    get coverUrl() {
      return this.listen?.coverart_url;
    }
    get enableProfileFallback() {
      return !this.show && this.user.custom_fields.listenbrainz_display === 'listen_profile_fallback';
    }
    get hasIcon() {
      return this.isPlayingNow || this.isPin;
    }
    get icon() {
      if (this.isPlayingNow) {
        return 'music';
      } else if (this.isPin) {
        return 'thumbtack';
      } else {
        return null;
      }
    }
    get iconTooltip() {
      if (this.isPlayingNow) {
        return I18n.t('listenbrainz.listening.playing_now_tooltip');
      } else if (this.isPin) {
        return I18n.t('listenbrainz.listening.pin_tooltip');
      } else {
        return null;
      }
    }
    get blurb() {
      return this.listen?.blurb_content;
    }
    get truncatedBlurb() {
      let blurb = (this.blurb || '').trim();
      if (blurb.length > maxBlurbLength) {
        blurb = blurb.substring(0, maxBlurbLength - 1) + '…';
      }
      return blurb;
    }
    async loadNowPlaying() {
      this.loading = true;
      try {
        const listen = await (0, _ajax.ajax)(`/listenbrainz/now-playing/${this.user.id}`);
        this.show = !!listen;
        this.listen = listen;
      } catch (err) {
        this.show = false;
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
  _exports.default = ListeningComponent;
});