define("discourse/plugins/listenbrainz/discourse/components/listenbrainz-preferences", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse-common/lib/get-owner", "I18n"], function (_exports, _component, _tracking, _object, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const displayOptions = ['profile', 'listen', 'listen_profile_fallback'];
  const rangeOptions = ['this_week', 'this_month', 'this_year', 'all_time'];
  const maxListenAgeOptions = ['0', '1', '24', '168', '720', '-1'];
  class PreferencesComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      this.user = args.user;
      const siteSettings = (0, _getOwner.getOwner)(this).lookup("service:site-settings");
      this.showPosterIconOption = siteSettings.listenbrainz_poster_icon;
      if (siteSettings.listenbrainz_username_matches_discourse) {
        this.setDefault('listenbrainz_username', this.user.username);
      }
      this.setDefault('listenbrainz_display', 'listen_profile_fallback');
      this.displayOptionsChanged(this.user.custom_fields.listenbrainz_display);
      this.setDefault('listenbrainz_profile_range', 'this_month');
      this.setDefault('listenbrainz_profile_range_fallback', true);
      this.setDefault('listenbrainz_max_listen_age_hours', '24');
    }
    static #_ = (() => dt7948.g(this.prototype, "showListenOptions", [_tracking.tracked], function () {
      return false;
    }))();
    #showListenOptions = (() => (dt7948.i(this, "showListenOptions"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "showProfileOptions", [_tracking.tracked], function () {
      return false;
    }))();
    #showProfileOptions = (() => (dt7948.i(this, "showProfileOptions"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "displayValue", [_tracking.tracked], function () {
      return null;
    }))();
    #displayValue = (() => (dt7948.i(this, "displayValue"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showPosterIconOption", [_tracking.tracked], function () {
      return null;
    }))();
    #showPosterIconOption = (() => (dt7948.i(this, "showPosterIconOption"), void 0))();
    get displayOptions() {
      return this.getTranslatedOptions(displayOptions, 'listenbrainz.preferences.display_options');
    }
    get rangeOptions() {
      return this.getTranslatedOptions(rangeOptions, 'listenbrainz.preferences.range_options');
    }
    get maxListenAgeOptions() {
      return this.getTranslatedOptions(maxListenAgeOptions, 'listenbrainz.preferences.listen_max_age_options');
    }
    displayOptionsChanged(value) {
      this.displayValue = value;
      this.user.custom_fields.listenbrainz_display = value;
      this.showListenOptions = value == 'listen' || value == 'listen_profile_fallback';
      this.showProfileOptions = value == 'profile' || value == 'listen_profile_fallback';
    }
    static #_5 = (() => dt7948.n(this.prototype, "displayOptionsChanged", [_object.action]))();
    setDefault(setting, value) {
      if (typeof this.user.custom_fields[setting] === 'undefined') {
        this.user.custom_fields[setting] = value;
      }
    }
    getTranslatedOptions(options, scope) {
      return options.map(o => {
        return {
          id: o,
          name: _I18n.default.t(`${scope}.${o}`)
        };
      });
    }
  }
  _exports.default = PreferencesComponent;
});