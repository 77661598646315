define("discourse/plugins/listenbrainz/discourse/templates/connectors/user-preferences-profile/listenbrainz-preferences", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.listenbrainz_enabled}}
  {{listenbrainz-preferences user=model}}
  {{/if}}
  
  */
  {
    "id": "7ACvvXof",
    "block": "[[[41,[30,0,[\"siteSettings\",\"listenbrainz_enabled\"]],[[[1,[28,[35,1],null,[[\"user\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/listenbrainz/discourse/templates/connectors/user-preferences-profile/listenbrainz-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/listenbrainz/discourse/templates/connectors/user-preferences-profile/listenbrainz-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"listenbrainz-preferences\"]]",
    "moduleName": "discourse/plugins/listenbrainz/discourse/templates/connectors/user-preferences-profile/listenbrainz-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});