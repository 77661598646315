define("discourse/plugins/listenbrainz/discourse/initializers/listenbrainz", ["exports", "discourse/lib/plugin-api", "virtual-dom", "I18n"], function (_exports, _pluginApi, _virtualDom, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'listenbrainz',
    initialize() {
      (0, _pluginApi.withPluginApi)('1.1.0', api => {
        api.addPosterIcons((cfs, attrs) => {
          if (Discourse.SiteSettings.listenbrainz_poster_icon && cfs.listenbrainz_poster_icon && cfs.listenbrainz_username) {
            return {
              icon: 'mb-listenbrainz',
              className: 'listenbrainz-profile-icon',
              title: _I18n.default.t('listenbrainz.profile_link_tooltip', {
                username: cfs.listenbrainz_username
              }),
              url: `https://listenbrainz.org/user/${cfs.listenbrainz_username}`
            };
          }
        });
        api.registerIconRenderer({
          name: 'mb-icons',
          string(icon, params) {
            if (icon.id == 'mb-listenbrainz') {
              return '<img class="svg-icon" src="/plugins/listenbrainz/images/listenbrainz.svg">';
            }
          },
          node(icon, params) {
            if (icon.id == 'mb-listenbrainz') {
              return (0, _virtualDom.h)('img', {
                attributes: {
                  class: 'svg-icon',
                  src: '/plugins/listenbrainz/images/listenbrainz.svg'
                }
              });
            }
          }
        });
      });
    }
  };
});