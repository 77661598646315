define("discourse/plugins/listenbrainz/discourse/components/listenbrainz-generic-cover", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template"], function (_exports, _component, _tracking, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GenericCoverComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "src", [_tracking.tracked], function () {
      return null;
    }))();
    #src = (() => (dt7948.i(this, "src"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "style", [_tracking.tracked], function () {
      return (0, _template.htmlSafe)('opacity: 0;');
    }))();
    #style = (() => (dt7948.i(this, "style"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "opacity", [_tracking.tracked], function () {
      return 0;
    }))();
    #opacity = (() => (dt7948.i(this, "opacity"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      this.src = args.src;
      this.title = args.title;
    }
    get showCover() {
      return !!this.src && navigator?.connection?.saveData !== true;
    }
    onLoad() {
      this.style = (0, _template.htmlSafe)('opacity: 1;');
    }
    static #_4 = (() => dt7948.n(this.prototype, "onLoad", [_object.action]))();
  }
  _exports.default = GenericCoverComponent;
});