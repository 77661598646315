define("discourse/plugins/listenbrainz/discourse/components/listenbrainz-profile", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/template", "discourse/lib/ajax", "I18n", "discourse/lib/utilities", "discourse/plugins/listenbrainz/discourse/lib/listenbrainz-utils"], function (_exports, _component, _tracking, _template, _ajax, _I18n, _utilities, _listenbrainzUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      this.user = args.user;
      this.loadProfile();
    }
    static #_ = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return true;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return true;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "profile", [_tracking.tracked], function () {
      return null;
    }))();
    #profile = (() => (dt7948.i(this, "profile"), void 0))();
    get listenCount() {
      if (!this.profile) return '';
      return _I18n.default.t(`listenbrainz.profile.ranges.${this.profile.range}`, {
        count: _I18n.default.toNumber(this.profile.listen_count, {
          precision: 0
        })
      });
    }
    get favoriteTrack() {
      const recording = this.profile.top_recording;
      let artist_mbid = null;
      if (recording.artist_mbids?.length === 1) {
        artist_mbid = recording.artist_mbids[0];
      }
      const artist = wrapMbidLink(recording.artist_name, 'artist', artist_mbid);
      const title = wrapMbidLink(recording.track_name, 'recording', recording.recording_mbid);
      return (0, _template.htmlSafe)(_I18n.default.t('listenbrainz.profile.top_recording', {
        artist,
        title
      }));
    }
    get coverUrl() {
      return this.profile?.thumbnail_url;
    }
    async loadProfile() {
      this.loading = true;
      try {
        const profile = await (0, _ajax.ajax)(`/listenbrainz/profile/${this.user.id}`);
        this.show = !!profile;
        this.profile = profile;
      } catch (err) {
        this.show = false;
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
  _exports.default = ProfileComponent;
  function wrapMbidLink(name, entity, mbid) {
    let html = (0, _utilities.escapeExpression)(name);
    if (html && mbid) {
      const url = (0, _listenbrainzUtils.buildMbidUrl)(entity, mbid);
      html = `<a href="${(0, _utilities.escapeExpression)(url)}" target="_blank">${html}</a>`;
    }
    return html;
  }
});